import React from "react"





export default () => {
    return (
       <h1>NOT FOUND :(</h1>
    )
}

